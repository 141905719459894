import axios from '../utils/axios'

import { handleError } from './utils/handleError'

export const getProjects = async () => {
  try {
    const response = await axios
      .post('Projects', {
        query: {
          select: ['name', 'id_projects', 'active', 'date_modification']
        }
      })

    return response.data
  } catch ({response}) {
    return handleError(response)
  }
}

export const updateProject = async (id, payload) => {
  try {
    const response = await  axios.post('Projects', { ...payload, ...id })

    return response.data
  } catch ({response}) {
    return handleError(response)
  }
}

export const deleteProject = async (id) => {
  try {
    const response = await axios.delete(`Projects/${id}`)

    return response.data
  } catch ({response}) {
    return handleError(response)
  }
}

export const triggerProjectAction = async (id, action) => {
  try {
    const response = await axios.get(`Barn/${action}/${id}`)

    return response.data
  } catch ({response}) {
    return handleError(response)
  }
}

export const getProjectEndpoints = async (id) => {
  try {
    const response = await axios.get(`Barn/doc/${id}?type=Endpoints`)

    return response.data
  } catch ({response}) {
    return handleError(response)
  }
}

export const getSchema = async (id) => {
  try {
    const response = await axios.get(`Barn/config/${id}`)

    return response.data
  } catch ({response}) {
    return handleError(response)
  }
}

export const editSchema = async (id, payload) => {
  try {
    const response = await axios.patch(`Barn/config/${id}`, payload)

    return response.data
  } catch ({response}) {
    return handleError(response)
  }
}

export const getProjectBehaviors = async () => {
  try {
    const response = await axios.post(
      'Behavior',
      JSON.stringify({
        query: {
          select: ['code'],
          filter: [
            ['group', 'Free', 'or'],
            ['group', '$session.Plan'],
            ['Status', 'Active']
          ]
        },
        debug: true
      })
    )

    return response.data
  } catch ({response}) {
    return handleError(response)
  }
}
