import { Button, Classes, Intent, Spinner } from '@blueprintjs/core'
import React, { FC, Fragment, useReducer, useState } from 'react'

import { slugify } from '../../utils/form.util'
import handleApiMessages from '../../utils/handleApiMessages'
import { updateProject } from '../../Api/Project'
import Input from '../../Components/Input'

import { IProject } from './Project'

const initialState = {
  name: '',
  admin_user: '',
  admin_password: '',
  id_projects: ''
}

const formReducer = (state, action) => {
  if (action.type === 'saveFormData') {
    const { key, value } = action.data

    return {
      ...state,
      [key]: value
    }
  } else if (action.type === 'resetForm') {
    return { ...initialState }
  } else if (action.type === 'setFormData') {
    const { name, admin_user, admin_password, id_projects } = action.data
    return { name, admin_user, admin_password, id_projects }
  } else {
    throw new Error(`That action type isn't supported.`)
  }
}

interface Props {
  project?: IProject
  cancel: () => void
  loadProjects: () => void
  renderError: (message) => void
  setLoading: (val: boolean) => void
  setShowProjectForm: (val: boolean) => void
}

const Form: FC<Props> = ({ project, cancel, loadProjects, renderError, setLoading, setShowProjectForm }) => {
  const [saving, setSaving] = useState(false)
  const [state, dispatch] = useReducer(formReducer, { ...(project || initialState) })

  const saveProject = () => {
    setSaving(true)

    const { id_projects, name, date_modification, active, ...data } = state

    const id = id_projects ? { id_projects } : {}
    const formName = id_projects ? {} : { name }

    updateProject(id, { ...data, ...formName })
      .then((response) => {
        dispatch({ type: 'resetForm' })
        setLoading(true)
        loadProjects()
        setShowProjectForm(false)
        setSaving(false)
      })
      .catch((error) => {
        renderError(handleApiMessages(error))
        setSaving(false)
      })
  }

  return (
    <Fragment>
      <form className={Classes.DIALOG_BODY}>
        <p className="message spaced-bottom">This is your root user for the backend section</p>
        {!state.id_projects && (
          <div className="inputWrapper">
            <Input
              required
              label="Project Name"
              theme="dark"
              value={state.name}
              onChange={(value) =>
                dispatch({
                  type: 'saveFormData',
                  data: {
                    key: 'name',
                    value: slugify(value)
                  }
                })
              }
            />
          </div>
        )}
        <div className="inputWrapper">
          <Input
            required
            label="Admin User"
            theme="dark"
            value={state.admin_user}
            autoComplete="off"
            onChange={(value) =>
              dispatch({
                type: 'saveFormData',
                data: { key: 'admin_user', value }
              })
            }
          />
        </div>
        <div className="inputWrapper">
          <Input
            required
            label="Admin Password"
            theme="dark"
            type="password"
            autoComplete="off"
            value={state.admin_password}
            onChange={(value) =>
              dispatch({
                type: 'saveFormData',
                data: {
                  key: 'admin_password',
                  value
                }
              })
            }
          />
        </div>
      </form>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {!saving && (
            <Fragment>
              <Button text="Cancel" onClick={cancel} />
              <Button
                icon="floppy-disk"
                intent={Intent.PRIMARY}
                text={!state.id_projects ? 'Add' : 'Update'}
                onClick={saveProject}
              />
            </Fragment>
          )}
          {saving && <Spinner className="savingSpinner" size={16} intent={Intent.PRIMARY} />}
        </div>
      </div>
    </Fragment>
  )
}

export default Form
