import { Intent, Spinner } from '@blueprintjs/core'
import * as QueryString from 'query-string'
import React, { Fragment, useEffect, useState } from 'react'
import PasswordStrengthBar from 'react-password-strength-bar'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import handleApiMessages from '../utils/handleApiMessages'
import { setSessionToken } from '../utils/Common'
import { getAuthyFromValidationKey, login, resetPassword } from '../Api/Account'
import Input, { useFormInput } from '../Components/Input'

import style from './login.module.scss'
import BackToLogin from './Components/BackToLogin'
import Error from './Components/Error'
import Form from './Components/Form'
import FormHeader from './Components/FormHeader'
import FormWrapper from './Components/FormWrapper'
import SubmitBtn from './Components/SubmitBtn'

const ResetPassword = ({ location, setSessionUser }) => {
  const history = useHistory()
  const [user, setUser] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any>(null)
  const password = useFormInput('')
  const confirmPassword = useFormInput('')
  const [validationKey, setValidationKey] = useState(false)

  const proceedLogin = () => {
    login(user.email, password.value)
      .then((response: any) => {
        setSessionToken(response.token)
        setSessionUser(user)
        history.push('/dashboard')
      })
      .catch((error) => {
        setLoading(false)
        setError(handleApiMessages(error))
      })
  }

  const updateAuthy = (e) => {
    e.preventDefault()
    setError(null)
    setLoading(true)
    resetPassword(validationKey, password.value)
      .then((response: any) => {
        proceedLogin()
      })
      .catch((error) => {
        setLoading(false)
        setError(handleApiMessages(error))
      })
  }

  useEffect(() => {
    const params = QueryString.parse(location.search)
    setError(null)
    setLoading(true)
    const key = params?.validationKey
    if (key) {
      setValidationKey(key)
      getAuthyFromValidationKey(key)
        .then((response: any) => {
          if (response.data) {
            setUser(response.data)
          } else {
            setError('The Validation Key is invalid')
          }
          setLoading(false)
        })
        .catch((error) => {
          setError(handleApiMessages(error))
          setLoading(false)
        })
    } else {
      setError('The Validation Key is invalid')
      setLoading(false)
    }
  }, [location])

  const validate = () => {
    if (password.value !== confirmPassword.value) {
      setError('Password and Confirm Password must match')
    } else {
      setError(null)
    }
  }

  // weird that this is a submit ...
  return (
    <FormWrapper>
      {loading && (
        <div className="projects-loading">
          <Spinner intent={Intent.PRIMARY} />
        </div>
      )}
      <Form onSubmit={updateAuthy}>
        <FormHeader title="Reset Password" />
        {!user && !loading && <Error text={error} />}
        {user && (
          <Fragment>
            <Input required placeholder="Password" onBlur={validate} type="password" {...password} />
            <PasswordStrengthBar className={style.passwordStrengthBar} password={password.value} />
            <Input
              required
              placeholder="Confirm password"
              onKeyUp={validate}
              type="password"
              autoComplete="new-password"
              {...confirmPassword}
            />
            {error && <Error text={error} />}

            <SubmitBtn label="Save" disabled={loading || error} />
          </Fragment>
        )}

        <BackToLogin disabled={loading} />
      </Form>
    </FormWrapper>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSessionUser: (data) => dispatch({ type: 'SET_SESSION_USER', data })
  }
}

export default connect(null, mapDispatchToProps)(ResetPassword)
