import React, { Fragment, useState } from 'react'

import handleApiMessages from '../utils/handleApiMessages'
import { sendEmail } from '../Api/Email'
import Input, { useFormInput } from '../Components/Input'

import BackToLogin from './Components/BackToLogin'
import Error from './Components/Error'
import Form from './Components/Form'
import FormHeader from './Components/FormHeader'
import FormWrapper from './Components/FormWrapper'
import Message from './Components/Message'
import SubmitBtn from './Components/SubmitBtn'

const Login = () => {
  const username = useFormInput('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any>(null)
  const [message, setMessage] = useState<any>(null)

  const handleForgottenPwd = (e) => {
    e.preventDefault()
    setError(null)
    setLoading(true)

    sendEmail(username.value, 'Forgotten password email')
      .then((response: any) => {
        setLoading(false)
        setError(null)
        setMessage(handleApiMessages(response))
      })
      .catch((error) => {
        setLoading(false)
        setError(handleApiMessages(error))
      })
  }

  return (
    <FormWrapper>
      <Form onSubmit={handleForgottenPwd}>
        <FormHeader title="Forgotten Password" />
        {message && <Message text={message} />}
        {!message && (
          <Fragment>
            <p>Enter your email and we'll send you a link to reset your password</p>
            <Input required placeholder="Email" {...username} />
            {error && <Error text={error} />}
            <SubmitBtn label="Send Email" disabled={loading} />
          </Fragment>
        )}

        <BackToLogin disabled={loading} />
      </Form>
    </FormWrapper>
  )
}

export default Login
