import { Classes, Drawer, Intent, ITreeNode, Position, Spinner, Tooltip, Tree } from '@blueprintjs/core'
import React, { Fragment, useEffect, useState } from 'react'

import handleApiMessages from '../../utils/handleApiMessages'
import { copyclip } from '../../utils/shared.utils'
import { GoatToaster } from '../../utils/GoatToaster'
import { getProjectEndpoints } from '../../Api/Project'
import { renderError } from '../../Shared/RenderError'

export default ({ projectId, setShowEndpoints }) => {
  const [forceUpdate, setForceUpdate] = useState(false)
  const [loading, setLoading] = useState(true)
  const [drawerInfo, setDrawerInfo] = useState<any>(null)

  useEffect(() => {
    getProjectEndpoints(projectId)
      .then((response) => {
        showDrawerEndpoint(response.data)
        setLoading(false)
      })
      .catch((error) => {
        renderError(handleApiMessages(error))
        setLoading(false)
      })
  }, [projectId])

  const showDrawerEndpoint = (data) => {
    let count = 0
    const nodes = data.reduce((acc, info) => {
      const fields = Object.keys(info['value']).reduce((prev, key, index) => {
        const node = {
          id: `${count}-${index}`,
          copyData: key,
          label: (
            <Tooltip content="Copy to Clipboard">
              <Fragment>
                {key}: {info['value'][key]}
              </Fragment>
            </Tooltip>
          )
        }

        return [...prev, node] as any
      }, [])

      count++
      return [
        ...acc,
        {
          id: count,
          isExpanded: false,
          copyData: info['name'],
          label: <Tooltip content="Copy to Clipboard">{info['name']}</Tooltip>,
          childNodes: fields
        }
      ]
    }, [])

    setDrawerInfo({
      count,
      treeContent: nodes
    })
  }

  const handleNodeClick = (nodeData) => {
    copyclip(nodeData.copyData)
    setForceUpdate(!forceUpdate)

    GoatToaster.show({
      timeout: 1000,
      icon: 'clipboard',
      intent: Intent.SUCCESS,
      message: 'Copied to Clipboard'
    })
  }

  const handleNodeCollapse = (nodeData: ITreeNode) => {
    nodeData.isExpanded = false
    setForceUpdate(!forceUpdate)
  }

  const handleNodeExpand = (nodeData: ITreeNode) => {
    nodeData.isExpanded = true
    setForceUpdate(!forceUpdate)
  }

  return (
    <Drawer
      onClose={() => setShowEndpoints(false)}
      title="Endpoints"
      canEscapeKeyClose={true}
      hasBackdrop={true}
      isOpen={true}
      position={Position.RIGHT}
      usePortal={true}
    >
      <div className={Classes.DRAWER_BODY}>
        <div className={Classes.DIALOG_BODY}>
          {loading ? (
            <Spinner size={30} intent={Intent.PRIMARY} />
          ) : (
            <Fragment>
              {!drawerInfo?.count && 'No Endpoints'}
              {!!drawerInfo?.count && (
                <Tree
                  contents={drawerInfo.treeContent}
                  onNodeClick={handleNodeClick}
                  onNodeCollapse={handleNodeCollapse}
                  onNodeExpand={handleNodeExpand}
                />
              )}
            </Fragment>
          )}
        </div>
      </div>
    </Drawer>
  )
}
