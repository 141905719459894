import { Button } from '@blueprintjs/core'
import cx from 'classnames'
import { TextInput } from 'grommet'
import React, { FC, SyntheticEvent, useState } from 'react'

import style from './style.module.scss'

interface Props {
  onChange: (value: string) => void
  onBlur?: (e: SyntheticEvent) => void
  onKeyUp?: (e: SyntheticEvent) => void
  autoComplete?: string
  placeholder?: string
  label?: string
  value: string
  type?: string
  theme?: 'dark' | 'light'
  required?: boolean
  children?: JSX.Element
}

export const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue)

  const handleChange = (value) => {
    setValue(value)
  }

  return {
    value,
    onChange: handleChange
  }
}

const Input: FC<Props> = ({
  autoComplete,
  onChange,
  onBlur,
  onKeyUp,
  placeholder,
  required,
  type = 'text',
  value,
  children,
  theme,
  label
}) => {
  const [localValue, setLocalValue] = useState(value)
  const [showPassword, setShowPassword] = useState(false)

  const handleChange = ({ target: { value } }) => {
    setLocalValue(value)
    onChange(value)
  }

  return (
    <label className={style.fieldWrapper}>
      {label && <span className="inputLabel">{label}</span>}
      <div className={style.inputWrapper}>
        <TextInput
          className={cx(style.field, { [style.dark]: theme === 'dark' })}
          type={showPassword ? 'text' : type}
          required={required}
          autoComplete={autoComplete}
          placeholder={placeholder}
          value={localValue}
          onBlur={onBlur}
          onKeyUp={onKeyUp}
          onChange={handleChange}
        />
        {type === 'password' && (
          <Button
            minimal
            className={style.inputButton}
            onClick={() => setShowPassword(!showPassword)}
            icon={showPassword ? 'eye-off' : 'eye-open'}
          />
        )}
      </div>
      {children}
    </label>
  )
}

export default Input
