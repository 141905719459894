import React, { useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'

import { getToken } from '../utils/axios'

// handle the private routes
const PrivateRoute = ({ user, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        getToken() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  )
}

export default PrivateRoute
