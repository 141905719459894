import { Button, Menu, MenuItem, Popover, Position } from '@blueprintjs/core'
import React from 'react'

import handleApiMessages from '../../utils/handleApiMessages'
import { deleteProject } from '../../Api/Project'
import { renderError } from '../../Shared/RenderError'

export default ({ project, loadProjects, setLoading, setShowEndpoints, setShowConfig, setEditProject }) => {
  const menu = (
    <Menu>
      <MenuItem icon="code" text="Edit schema" onClick={() => setShowConfig(true)} />
      <MenuItem icon="code" text="View API Endpoints" onClick={() => setShowEndpoints(true)} />
      <MenuItem icon="edit" text="Edit Admin User" onClick={() => setEditProject(project)} />
      <MenuItem
        icon="trash"
        text="Delete"
        onClick={() => {
          if (window.confirm('Are you sure you want to delete this project?')) {
            setLoading(true)
            deleteProject(project.id_projects)
              .then((response) => {
                loadProjects()
              })
              .catch((error) => {
                renderError(handleApiMessages(error))
                setLoading(false)
              })
          }
        }}
      />
    </Menu>
  )
  return (
    <header>
      <h1 className="title">{project.name}</h1>
      <span className="date-modif">{project.date_modification}</span>

      <Popover className="projectMenuBtn" content={menu} position={Position.RIGHT_TOP}>
        <Button minimal icon="menu" />
      </Popover>
    </header>
  )
}
