import axios from '../utils/axios'

import { handleError } from './utils/handleError'

export const sendEmail = async (email, template_name) => {
  try {
    const response = await axios.post('ApiGoat/sendEmail', {
      email,
      template_name
    })

    return response.data
  } catch ({response}) {
    return handleError(response)
  }
}
