import { AnchorButton } from '@blueprintjs/core'
import React from 'react'

import fb from '../../../assets/fb-logo.png'
import github from '../../../assets/github-logo.png'

import style from './style.module.scss'

const SocialLinks = ({ disabled }) => {
  return (
    <div className={style.socialLinks}>
      <AnchorButton
        disabled={disabled}
        href="https://x.apigoat.com/api/v1/oauth/facebook"
        className={`${style.facebookBtn} ${style.socialBtn}`}
      >
        <img src={fb} alt="Continue with Facebook" width={28} />
      </AnchorButton>
      <AnchorButton
        disabled={disabled}
        href="https://x.apigoat.com/api/v1/oauth/github"
        className={`${style.githubBtn} ${style.socialBtn}`}
      >
        <img src={github} alt="Continue with GitHub" width={28} />
      </AnchorButton>
    </div>
  )
}

export default SocialLinks
