import { Intent, Spinner } from '@blueprintjs/core'
import { Grommet } from 'grommet'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Switch } from 'react-router-dom'

import logo from './assets/logo.svg'
import axios from './utils/axios'
import { getToken } from './utils/axios'
import { removeSessionToken, setSessionToken } from './utils/Common'
import ConfirmEmail from './Account/ConfirmEmail'
import Forgotten from './Account/Forgotten'
import Login from './Account/Login'
import Register from './Account/Register'
import ResetPassword from './Account/ResetPassword'
import PrivateRoute from './Components/PrivateRoute'
import PublicRoute from './Components/PublicRoute'
import Dashboard from './Dashboard'

const App = ({ user, removeSessionUser, setSessionUser }) => {
  const hasToken = getToken()
  const [authLoading, setAuthLoading] = useState(!!hasToken)

  useEffect(() => {
    const token = getToken()
    if (!token) {
      return
    }

    axios
      .get('ApiGoat/account')
      .then((response) => {
        setSessionToken(token)
        setSessionUser(response.data.data)
        setAuthLoading(false)
      })
      .catch((error) => {
        removeSessionToken()
        removeSessionUser()
        setAuthLoading(false)
      })
  }, [])

  if (authLoading && hasToken) {
    return (
      <div className="projects-loading">
        <Spinner intent={Intent.PRIMARY} />
      </div>
    )
  }

  const handleLogout = () => {
    delete axios.defaults.headers.common['Authorization']
    removeSessionToken()
    removeSessionUser()
  }

  return (
    <Grommet className="App" plain>
      <BrowserRouter>
        {user && (
          <div className="header">
            <img src={logo} alt="APIgoat Logo" width={40} />
            <button type="button" className="logout" onClick={handleLogout}>
              Logout
            </button>
          </div>
        )}
        <div className="content">
          <Switch>
            <PublicRoute path="/login" component={Login} />
            <PublicRoute path="/register" component={Register} />
            <PublicRoute path="/forgotten-password" component={Forgotten} />
            <PublicRoute path="/reset-password" component={ResetPassword} />
            <PublicRoute path="/confirm" component={ConfirmEmail} />
            <PrivateRoute user={user} path="/" component={Dashboard} />
          </Switch>
        </div>
      </BrowserRouter>
    </Grommet>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = (dispatch) => ({
  removeSessionUser: () => dispatch({ type: 'REMOVE_SESSION_USER' }),
  setSessionUser: (data) => dispatch({ type: 'SET_SESSION_USER', data })
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
