import { Button, MenuItem } from '@blueprintjs/core'
import { Select as BPSelect } from '@blueprintjs/select'
import cx from 'classnames'
import React, { FC, useState } from 'react'

import style from './style.module.scss'

interface Props {
  options: {
    label: string
    value: string | null
  }[]
  onChange: (value: string) => void
  multiple?: boolean
  placeholder?: string
  label?: string
  value: string
  required?: boolean
  children?: JSX.Element
}

const Select: FC<Props> = ({ options, onChange, placeholder, value, children, label }) => {
  const [localValue, setLocalValue] = useState<any>(options.find((o) => o.value === value))
  const ReusableSelect = BPSelect.ofType<any>()

  const handleChange = (value) => {
    setLocalValue(value)
    onChange(value.value)
  }

  const itemRenderer = (item, { handleClick, query }) => {
    if (!item.label.toLowerCase().includes(query.toLowerCase())) {
      return null
    }

    return <MenuItem key={item.value} text={item.label} onClick={handleClick} />
  }

  return (
    <label className={style.fieldWrapper}>
      {label && <span className="inputLabel">{label}</span>}
      <ReusableSelect
        className={style.select}
        items={options}
        filterable
        itemRenderer={itemRenderer}
        onItemSelect={handleChange}
        popoverProps={{ minimal: true, usePortal: false }}
      >
        <Button
          className={cx({ [style.placeholder]: !localValue })}
          text={localValue ? localValue.label : placeholder}
          rightIcon="caret-down"
        />
      </ReusableSelect>
      {children}
    </label>
  )
}

export default Select
