import React, { Fragment, useState } from 'react'

import { validateEmail } from '../utils/form.util'
import handleApiMessages from '../utils/handleApiMessages'
import { register } from '../Api/Account'
import { sendEmail } from '../Api/Email'
import Input, { useFormInput } from '../Components/Input'

import BackToLogin from './Components/BackToLogin'
import Error from './Components/Error'
import Form from './Components/Form'
import FormFooter from './Components/FormFooter'
import FormHeader from './Components/FormHeader'
import FormWrapper from './Components/FormWrapper'
import Message from './Components/Message'
import Plans from './Components/Plans'
import SocialLinks from './Components/SocialLinks'
import SubmitBtn from './Components/SubmitBtn'

const Register = () => {
  const [loading, setLoading] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState('free')
  const username = useFormInput('')
  const [error, setError] = useState<any>(null)
  const [message, setMessage] = useState<any>(null)

  /**
   * Registration requests: create user and send email
   */
  const handleRegister = (e) => {
    e.preventDefault()
    const email = username.value
    setError(null)
    setLoading(true)

    if (validateEmail(email)) {
      register(email)
        .then((response: any) => {
          setLoading(false)
          sendEmail(email, 'Confirm your email')
            .then((response: any) => {
              setLoading(false)
              if (response.status === 'success') {
                setMessage(
                  `An email has been sent to <strong>${email}</strong> to confirm your email and activate your account.`
                )
              }
            })
            .catch((error) => {
              setLoading(false)
              setError(handleApiMessages(error))
            })
        })
        .catch((error) => {
          setLoading(false)
          setError(handleApiMessages(error))
        })
    } else {
      setLoading(false)
      setError('Tis email is not valid, try again!')
    }
  }

  return (
    <FormWrapper>
      <Form onSubmit={handleRegister}>
        <FormHeader title="Register" />
        {!message && (
          <Fragment>
            <Input required placeholder="Email" {...username} />
            <Plans setSelectedPlan={setSelectedPlan} selectedPlan={selectedPlan} />

            {error && <Error text={error} />}

            <SubmitBtn label="Register" disabled={loading} />
            <SocialLinks disabled={loading} />
          </Fragment>
        )}
        {message && (
          <Fragment>
            <Message text={message} />
            <BackToLogin disabled={loading} />
          </Fragment>
        )}
      </Form>
      <FormFooter message="Already have an account?" btnLabel="Sign In" link="/login" />
    </FormWrapper>
  )
}

export default Register
