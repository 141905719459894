import { AnchorButton, Spinner, Tooltip } from '@blueprintjs/core'
import React from 'react'

interface Props {
  tooltip?: JSX.Element | string
  onClick?: () => void
  href?: string
  intent?: 'none' | 'primary' | 'success' | 'warning' | 'danger' | undefined
  disabled?: boolean
  loading?: boolean
  content: JSX.Element | string
  target?: string
}

export default ({ tooltip, onClick, href, target, intent, disabled, loading, content }: Props) => (
  <Tooltip hoverCloseDelay={0} hoverOpenDelay={400} content={tooltip}>
    <AnchorButton target={target} href={href} onClick={onClick} intent={intent} disabled={disabled}>
      {!loading && content}
      {loading && <Spinner size={16} />}
    </AnchorButton>
  </Tooltip>
)
