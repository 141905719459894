import { createStore } from 'redux'

const defaultState = {
  user: null
}

const appReducer = (state, action) => {
  switch (action.type) {
    case "SET_SESSION_USER":
      return {
        state,
        user: action.data
      }
    case "REMOVE_SESSION_USER":
      return {
        state,
        user: null
      }
    default:
      return state
  }
}

export const store = createStore(appReducer, defaultState)
