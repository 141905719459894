import { Button } from '@blueprintjs/core'
import React from 'react'
import { useHistory } from 'react-router-dom'

import style from './style.module.scss'

const BackToLogin = ({ disabled }) => {
  const history = useHistory()

  return (
    <Button
      minimal
      small
      className={style.backBtn}
      icon="chevron-left"
      onClick={() => history.push('/login')}
      disabled={disabled}
    >
      Back to login
    </Button>
  )
}
export default BackToLogin
