import { Button, Dialog, Intent, Spinner } from '@blueprintjs/core'
import React, { Fragment, useEffect, useState } from 'react'

import { getProjects } from '../Api/Project'
import { renderError } from '../Shared/RenderError'

import './dashboard.scss'
import Form from './Components/Form'
import Project, { IProject } from './Components/Project'

const Dashboard = (props) => {
  const [projects, setProjects] = useState<any>([])
  const [editProject, setEditProject] = useState<IProject>()
  const [loading, setLoading] = useState(true)
  const [showProjectForm, setShowProjectForm] = useState(false)

  useEffect(() => {
    loadProjects()
  }, [])

  const loadProjects = () => {
    getProjects()
      .then((response) => {
        setProjects(response.data)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  const handleEditProject = (project) => {
    setEditProject(project)
    setShowProjectForm(true)
  }

  const cancel = () => {
    setShowProjectForm(false)
    setEditProject(undefined)
  }

  return (
    <Fragment>
      {loading && (
        <div className="projects-loading">
          <Spinner intent={Intent.PRIMARY} />
        </div>
      )}
      <div className="projects-wrapper">
        <div className="projects-header">
          <h1>Your Projects</h1>
          <Button
            icon="plus"
            intent={Intent.SUCCESS}
            text="Create new project"
            onClick={() => setShowProjectForm(true)}
          />
        </div>
        <div className="projects">
          {!projects?.length && !loading && <p className="emptyProject">No projects yet</p>}
          {projects?.map((project, index) => (
            <Project
              key={index}
              project={project}
              loadProjects={loadProjects}
              setEditProject={handleEditProject}
              setLoading={setLoading}
              renderError={renderError}
            />
          ))}
        </div>
      </div>

      {showProjectForm && (
        <Dialog onClose={cancel} title={!editProject ? 'Add Project' : 'Edit Project'} isOpen={true}>
          <Form
            project={editProject}
            cancel={cancel}
            loadProjects={loadProjects}
            renderError={renderError}
            setLoading={setLoading}
            setShowProjectForm={setShowProjectForm}
          />
        </Dialog>
      )}
    </Fragment>
  )
}

export default Dashboard
