import { Classes, Drawer, Icon, Intent, ITreeNode, Position, Tab, Tabs, Tree } from '@blueprintjs/core'
import React, { FC, Fragment, useState } from 'react'

import { copyclip } from '../../utils/shared.utils'
import { GoatToaster } from '../../utils/GoatToaster'
import { triggerProjectAction } from '../../Api/Project'
import '../dashboard.scss'

import ProjectButton from './Button'
import Endpoints from './Endpoints'
import Header from './Header'
import SchemaEditor from './SchemaEditor'

export interface IProject {
  active: string
  date_modification: string
  id_projects: string
  name: string
}

interface Props {
  project: IProject
  loadProjects: () => void
  renderError: (message: string) => void
  setEditProject: (project: IProject) => void
  setLoading: (show: boolean) => void
}

const Project: FC<Props> = ({ project, loadProjects, setEditProject, setLoading }) => {
  const [forceUpdate, setForceUpdate] = useState(false)
  const [showConfig, setShowConfig] = useState(false)
  const [showEndpoints, setShowEndpoints] = useState(false)
  const [drawerInfo, setDrawerInfo] = useState<any>(null)
  const [loadingAction, setLoadingAction] = useState<any>(null)

  const triggerAction = (id, action) => {
    setLoadingAction({ id, action })

    triggerProjectAction(id, action)
      .then((response) => {
        setLoadingAction(null)
        showDrawerInfo(response.messages)
      })
      .catch((error) => {
        // showDrawerInfo(error.response.data.messages)
        setLoadingAction(null)
      })
  }

  const showDrawerInfo = (data) => {
    const info = data
      .reduce((acc, { info }) => {
        acc.push(info?.filter(Boolean))
        return acc
      }, [])
      ?.filter((item) => item?.filter(Boolean).length)

    const error = data
      .reduce((acc, { error }) => {
        acc.push(error?.filter(Boolean))

        return acc
      }, [])
      ?.filter((item) => item?.filter(Boolean).length)

    setDrawerInfo({
      info: {
        count: info.length,
        html: info.reduce((acc, info) => {
          const str = `<h2>${info.shift()}</h2><ul>${info.reduce((acc, info) => {
            acc += `<li>${info}</li>`
            return acc
          }, [])}</ul>`

          return `${acc}${str}`
        }, '')
      },
      error: {
        count: error.length,
        html: error.reduce((acc, error) => {
          const str = `<h2>${error.shift()}</h2><ul>${error.reduce((acc, info) => {
            acc += `<li>${info}</li>`
            return acc
          }, [])}</ul>`

          return `${acc}${str}`
        }, '')
      }
    })
  }

  const handleNodeClick = (nodeData) => {
    copyclip(nodeData.copyData)
    setForceUpdate(!forceUpdate)

    GoatToaster.show({
      timeout: 1000,
      icon: 'clipboard',
      intent: Intent.SUCCESS,
      message: 'Copied to Clipboard'
    })
  }

  const handleNodeCollapse = (nodeData: ITreeNode) => {
    nodeData.isExpanded = false
    setForceUpdate(!forceUpdate)
  }

  const handleNodeExpand = (nodeData: ITreeNode) => {
    nodeData.isExpanded = true
    setForceUpdate(!forceUpdate)
  }

  const buttons = [
    {
      tooltip: 'Compiles fields and database, but also restores data from the last backup',
      key: 'buildRestore',
      intent: Intent.SUCCESS,
      content: (
        <Fragment>
          <Icon icon="build" />
          <Icon icon="small-plus" iconSize={12} />
          <Icon icon="database" />
          <Icon icon="small-plus" iconSize={12} />
          <Icon icon="cloud-upload" />
          <span className="label">Compile and Restore</span>
        </Fragment>
      )
    },
    {
      tooltip: 'Updates the data used for the next restore',
      key: 'dumpData',
      intent: Intent.NONE,
      content: (
        <Fragment>
          <Icon icon="cloud-download" />
          <span className="label">DB Dump</span>
        </Fragment>
      )
    },
    {
      tooltip: 'Compiles files and database (drops table, compiles files and rebuilds empty DB',
      key: 'modeldb',
      intent: Intent.PRIMARY,
      content: (
        <Fragment>
          <Icon icon="build" />
          <Icon icon="small-plus" iconSize={12} />
          <Icon icon="database" />
          <span className="label">Compile</span>
        </Fragment>
      )
    },
    {
      tooltip: 'Compiles only files',
      key: 'model',
      intent: Intent.NONE,
      content: (
        <Fragment>
          <Icon icon="build" />
          <span className="label">Compile Files</span>
        </Fragment>
      )
    }
  ]

  return (
    <Fragment>
      <div className={`project ${project.active === 'Yes' && 'active'}`}>
        <Header
          project={project}
          loadProjects={loadProjects}
          setShowConfig={setShowConfig}
          setShowEndpoints={setShowEndpoints}
          setEditProject={setEditProject}
          setLoading={setLoading}
        />
        <div className="body">
          {buttons.map(({ tooltip, key, intent, content }) => (
            <ProjectButton
              key={key}
              tooltip={tooltip}
              onClick={() => triggerAction(project.id_projects, key)}
              intent={intent}
              disabled={loadingAction?.id === project.id_projects}
              loading={loadingAction?.id === project.id_projects && loadingAction?.action === key}
              content={content}
            />
          ))}
          <ProjectButton
            href={`https://x.apigoat.com/p/${project.name}`}
            target="_blank"
            content={
              <Fragment>
                <Icon icon="share" />
                <span className="label">Open</span>
              </Fragment>
            }
            disabled={loadingAction?.id === project.id_projects}
          />
        </div>
      </div>
      {showEndpoints && <Endpoints setShowEndpoints={setShowEndpoints} projectId={project.id_projects} />}
      {showConfig && <SchemaEditor onClose={() => setShowConfig(false)} projectId={project.id_projects} />}
      <Drawer
        icon="info-sign"
        onClose={() => setDrawerInfo(null)}
        title="Info"
        canEscapeKeyClose={true}
        hasBackdrop={true}
        isOpen={!!drawerInfo}
        position={Position.RIGHT}
        usePortal={true}
      >
        <div className={Classes.DRAWER_BODY}>
          <div className={Classes.DIALOG_BODY}>
            <Tabs animate={true}>
              <Tab
                id="info"
                title={`Info (${drawerInfo?.info?.count || 0})`}
                panel={
                  <Fragment>
                    {!drawerInfo?.info?.count && 'No Info'}
                    {!!drawerInfo?.info?.count && (
                      <Fragment>
                        {drawerInfo.info.html && (
                          <pre
                            dangerouslySetInnerHTML={{
                              __html: drawerInfo?.info?.html!
                            }}
                          ></pre>
                        )}
                        {drawerInfo.info.treeContent && (
                          <Tree
                            contents={drawerInfo.info.treeContent}
                            onNodeClick={handleNodeClick}
                            onNodeCollapse={handleNodeCollapse}
                            onNodeExpand={handleNodeExpand}
                          />
                        )}
                      </Fragment>
                    )}
                  </Fragment>
                }
              />
              <Tab
                id="errors"
                title={`Errors (${drawerInfo?.error?.count || 0})`}
                panel={
                  <Fragment>
                    {!drawerInfo?.error?.count && 'No Errors'}
                    {!!drawerInfo?.error?.count && (
                      <pre
                        dangerouslySetInnerHTML={{
                          __html: drawerInfo?.error?.html!
                        }}
                      ></pre>
                    )}
                  </Fragment>
                }
              />
            </Tabs>
          </div>
        </div>
      </Drawer>
    </Fragment>
  )
}

export default Project
