import { Intent, Spinner } from '@blueprintjs/core'
import md5 from 'md5'
import * as QueryString from 'query-string'
import React, { Fragment, useEffect, useState } from 'react'
import PasswordStrengthBar from 'react-password-strength-bar'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import handleApiMessages from '../utils/handleApiMessages'
import { countryList, setSessionToken } from '../utils/Common'
import { getAuthy, getAuthyFromValidationKey, login, updateUser, updateUserFromValidationKey } from '../Api/Account'
import Input, { useFormInput } from '../Components/Input'
import Select from '../Components/Select'

import style from './login.module.scss'
import BackToLogin from './Components/BackToLogin'
import Error from './Components/Error'
import Form from './Components/Form'
import FormHeader from './Components/FormHeader'
import FormWrapper from './Components/FormWrapper'
import SubmitBtn from './Components/SubmitBtn'

const ConfirmEmail = ({ location, setSessionUser }) => {
  const history = useHistory()
  const [user, setUser] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any>(null)
  const [message, setMessage] = useState<string>('Looking...')
  const [validationKey, setValidationKey] = useState('')
  const name = useFormInput('')
  const company = useFormInput('')
  const country = useFormInput(null)
  const password = useFormInput('')
  const confirmPassword = useFormInput('')

  const handleErrors = (response, msg?) => {
    setLoading(false)
    setError(handleApiMessages(response))

    if (msg) {
      setMessage(msg)
    }
  }

  const proceedLogin = () => {
    login(user.email, password.value)
      .then((response: any) => {
        setSessionToken(response.token)
        setSessionUser(user)
        history.push('/dashboard')
      })
      .catch(handleErrors)
  }

  const tokenLogin = (u) => {
    const params = QueryString.parse(location.search)
    const token = params['jwt[token]']

    setSessionToken(token)
    setSessionUser(u)
    history.push('/dashboard')
  }

  const handleUpdateUser = (payload) => {
    updateUser(user.id_authy, { ...payload, id_authy_group: 1 })
      .then((response: any) => {
        if (response.status === 'success') {
          tokenLogin(user)
        } else {
          handleErrors(response, 'Oups, something is not right with your key!')
        }
      })
      .catch((error) => {
        handleErrors(error, 'Oups!')
      })
  }

  const handleUpdateFromValidationKey = (payload) => {
    updateUserFromValidationKey(validationKey, payload)
      .then((response: any) => {
        if (response.status === 'success') {
          proceedLogin()
        } else {
          handleErrors(response, 'Oups, something is not right with your key!')
        }
      })
      .catch((error) => {
        handleErrors(error, 'Oups!')
      })
  }

  const updateAuthy = (e) => {
    e.preventDefault()
    setError(null)
    if (isFormValid()) {
      setLoading(true)

      const payload = {
        validation_key: '',
        /*company: company.value,
        country: country.value,*/
        fullname: name.value,
        passwd_hash: md5(password.value),
        deactivate: 'No'
      }

      if (validationKey) {
        handleUpdateFromValidationKey(payload)
      } else {
        handleUpdateUser(payload)
      }
    }
  }

  const proceedKey = (key) => {
    getAuthyFromValidationKey(key)
      .then((response: any) => {
        setLoading(false)

        if (response?.data?.id_authy) {
          setUser(response.data)
          setMessage('')
        } else {
          setMessage('Oups, your key is invalid!')
        }
      })
      .catch((error) => {
        handleErrors(error, 'Oups!')
      })
  }

  const proceedId = (id) => {
    const params = QueryString.parse(location.search)
    const action = params?.action

    getAuthy(id)
      .then((response: any) => {
        setLoading(false)

        if (response?.data?.id_authy) {
          const u = response.data

          if (action === 'login') {
            tokenLogin(u)
            return
          }

          name.onChange(u.fullname)
          setUser(u)
          setMessage('')
        } else {
          setMessage('Oups, something went wrong, please try again later.')
        }
      })
      .catch((error) => {
        handleErrors(error, 'Oups!')
      })
  }

  useEffect(() => {
    const params = QueryString.parse(location.search)
    const key = params?.validationKey
    const id = params?.id

    setError(null)
    setLoading(true)

    if (key) {
      setValidationKey(key)
      proceedKey(key)
    } else if (id) {
      proceedId(id)
    } else {
      setLoading(false)
      setError('Oups, your key is missing!')
    }
  }, [location])

  const isFormValid = () => {
    if (password.value === confirmPassword.value && country.value) {
      return true
    } else if (!country.value) {
      setError('You must choose your country')
    } else {
      validateForm()
    }

    return false
  }

  const validateForm = () => {
    if (password.value !== confirmPassword.value) {
      setError('Password and Confirm Password must match')
    } else {
      setError(null)
    }
  }

  return (
    <FormWrapper>
      {loading ? (
        <div className="projects-loading">
          <Spinner intent={Intent.PRIMARY} />
        </div>
      ) : (
        <Form onSubmit={updateAuthy}>
          <FormHeader title={message || 'Complete your profile'} />

          {user && (
            <Fragment>
              <Input required placeholder="Full Name*" {...name} />
              <Input placeholder="Company" {...company} />
              <Select placeholder="Country*" {...country} options={countryList} />
              <Input required placeholder="Password*" onBlur={validateForm} type="password" {...password} />
              <PasswordStrengthBar className={style.passwordStrengthBar} password={password.value} />
              <Input
                required
                placeholder="Confirm password*"
                onKeyUp={validateForm}
                type="password"
                autoComplete="new-password"
                {...confirmPassword}
              />

              <SubmitBtn label="Submit" disabled={loading} />
            </Fragment>
          )}
          {error && <Error text={error} />}

          <BackToLogin disabled={loading} />
        </Form>
      )}
    </FormWrapper>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSessionUser: (data) => dispatch({ type: 'SET_SESSION_USER', data })
  }
}

export default connect(null, mapDispatchToProps)(ConfirmEmail)
