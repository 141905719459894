import { Button, ButtonGroup, Intent } from '@blueprintjs/core'
import React from 'react'

import style from './style.module.scss'

const Plans = ({ setSelectedPlan, selectedPlan }) => (
  <div className={style.plans}>
    <h2>Plan</h2>
    <ButtonGroup>
      <Button
        intent={selectedPlan === 'free' ? Intent.SUCCESS : Intent.NONE}
        disabled={true}
        onClick={() => setSelectedPlan('free')}
      >
        Free
      </Button>
      <Button
        intent={selectedPlan === 'standard' ? Intent.SUCCESS : Intent.NONE}
        disabled={true}
        onClick={() => setSelectedPlan('standard')}
      >
        Standard
      </Button>
      <Button
        intent={selectedPlan === 'premium' ? Intent.SUCCESS : Intent.NONE}
        disabled={true}
        onClick={() => setSelectedPlan('premium')}
      >
        Premium
      </Button>
      <Button
        intent={selectedPlan === 'overloaded' ? Intent.SUCCESS : Intent.NONE}
        disabled={true}
        onClick={() => setSelectedPlan('overloaded')}
      >
        Overloaded
      </Button>
    </ButtonGroup>
  </div>
)

export default Plans
