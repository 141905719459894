import md5 from 'md5'

import axios from '../utils/axios'

import { handleError } from './utils/handleError'

export const login = async (username, password) => {
  try {
    const response = await axios.post('Authy/auth', {
      u: username,
      pw: md5(password)
    })

    return response.data
  } catch ({response}) {
    return handleError(response)
  }
}

export const register = async (email) => {
  try {
    const response = await axios.post('Authy', {
      email,
      passwd_hash: md5(generatePassword()),
      id_authy_group: 1
    })

    return response.data
  } catch ({response}) {
    return handleError(response)
  }
}

export const resetPassword = async (validationKey, password) => {
  try {
    const response = await axios.post(`ApiGoat/reset/${validationKey}`, {
      passwd_hash: md5(password),
      validation_key: ''
    })

    return response.data
  } catch ({response}) {
    return handleError(response)
  }
}

export const getAuthy = async (id) => {
  try {
    const response = await axios.get(`Authy/${id}`)

    return response.data
  } catch ({response}) {
    return handleError(response)
  }
}

export const getAuthyFromValidationKey = async (validationKey) => {
  try {
    const response = await axios.post('Authy', {
      query: {
        select: ['validation_key', 'id_authy', 'email'],
        filter: {
          Authy: [['validation_key', validationKey]]
        },
        table: 'authy',
        limit: 1
      }
    })

    return response.data
  } catch ({response}) {
    return handleError(response)
  }
}

export const updateUserFromValidationKey = async (validationKey, payload) => {
  try {
    const response = await axios.patch('Authy', {
      query: {
        filter: {
          Authy: [['validation_key', validationKey]]
        }
      },
      ...payload
    })

    return response.data
  } catch ({response}) {
    return handleError(response)
  }
}

export const updateUser = async (id, payload) => {
  try {
    const response = await axios.patch(`Authy/${id}`, payload)

    return response.data
  } catch ({response}) {
    return handleError(response)
  }
}

function generatePassword() {
  const length = 8
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
  let retVal = ""

  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n))
  }

  return retVal
}
