import { Button, Intent } from '@blueprintjs/core'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import handleApiMessages from '../utils/handleApiMessages'
import { setSessionToken } from '../utils/Common'
import { login } from '../Api/Account'
import Input, { useFormInput } from '../Components/Input'

import style from './login.module.scss'
import Error from './Components/Error'
import Form from './Components/Form'
import FormFooter from './Components/FormFooter'
import FormHeader from './Components/FormHeader'
import FormWrapper from './Components/FormWrapper'
import SocialLinks from './Components/SocialLinks'
import SubmitBtn from './Components/SubmitBtn'

const Login = ({ setSessionUser }) => {
  const history = useHistory()
  const username = useFormInput('')
  const password = useFormInput('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any>(null)

  const handleLogin = (e) => {
    e.preventDefault()
    setError(null)
    setLoading(true)

    login(username.value, password.value)
      .then((response: any) => {
        setLoading(false)
        setSessionToken(response.token)
        setSessionUser(response)
        history.push('/')
      })
      .catch((error) => {
        setLoading(false)
        setError(handleApiMessages(error))
      })
  }

  return (
    <FormWrapper>
      <Form onSubmit={handleLogin}>
        <FormHeader title="Sign In" />
        <Input required placeholder="Email" {...username} />

        <Input required placeholder="Password" type="password" autoComplete="new-password" {...password}>
          <Button
            className={style.forgottenPwdBtn}
            minimal
            small
            onClick={() => history.push('/forgotten-password')}
            intent={Intent.PRIMARY}
          >
            Forgot Password?
          </Button>
        </Input>

        {error && <Error text={error} />}
        <SubmitBtn label="Sign In" disabled={loading} />
        <SocialLinks disabled={loading} />
      </Form>
      <FormFooter message="No account?" btnLabel="Create an Account" link="/register" />
    </FormWrapper>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSessionUser: (data) => dispatch({ type: 'SET_SESSION_USER', data })
  }
}

export default connect(null, mapDispatchToProps)(Login)
