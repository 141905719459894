import { Intent } from '@blueprintjs/core'

import { GoatToaster } from '../utils/GoatToaster'

export const renderError = (error) => {
  GoatToaster.show({
    timeout: 5000,
    icon: 'warning-sign',
    intent: Intent.DANGER,
    message: error || 'Oups! Something went horribly wrong!.'
  })
}
