import React from 'react'
import { useHistory } from 'react-router-dom'

import style from './style.module.scss'

const FormFooter = ({ message, btnLabel, link }) => {
  const history = useHistory()

  return (
    <div className={style.formFooter}>
      {message}
      <button
        type="button"
        onClick={() => {
          history.push(link)
        }}
        className={style.footerBtn}
      >
        {btnLabel}
      </button>
    </div>
  )
}

export default FormFooter
