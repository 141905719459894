import { Button, Menu, MenuItem, Popover, Spinner } from '@blueprintjs/core'
import Hjson from 'hjson'
import React, { FC, Fragment, useEffect, useRef, useState } from 'react'
import AceEditor from 'react-ace'

import { getProjectBehaviors } from '../../Api/Project'

/* tslint:disable */
import 'ace-builds/src-min-noconflict/ext-language_tools'
import 'ace-builds/src-min-noconflict/ext-searchbox'
import 'ace-builds/src-noconflict/mode-hjson'
import 'ace-builds/src-noconflict/snippets/hjson'
import 'ace-builds/src-noconflict/theme-monokai'
import 'ace-builds/src-noconflict/theme-xcode'
/* tslint:enable */

const Editor: FC<{
  name: string
  value: string
  mode?: string
  updateParentValue: any
  onSave: () => void
}> = (props) => {
  const [loading, setLoading] = useState(true)
  const [collapsed, setCollapsed] = useState(true)
  const [value, setValue] = useState(props.value)
  const [behaviors, setBehaviors] = useState([])
  const [fontSize, setFontSize] = useState(15)
  const [theme, setTheme] = useState('monokai')

  const aceRef = useRef<AceEditor>(null)
  const editor = aceRef.current?.editor

  useEffect(() => {
    getBehaviors()
  }, [])

  useEffect(() => {
    if (!editor) {
      return
    }

    aceRef.current?.editor.getSession().setMode('ace/mode/hjson', () => {
      const session = editor.session
      // @ts-ignore
      const rules = session.$mode.$highlightRules.getRules()

      for (const stateName in rules) {
        if (Object.prototype.hasOwnProperty.call(rules, stateName)) {
          rules[stateName].unshift({
            token: 'modifier',
            regex: behaviors
          })
        }
      }

      // force recreation of tokenizer
      // @ts-ignore
      session.$mode.$tokenizer = null
      // @ts-ignore
      session.bgTokenizer.setTokenizer(session.$mode.getTokenizer())
      // force re-highlight whole document
      // @ts-ignore
      session.bgTokenizer.start(0)
    })
    // eslint-disable-next-line
  }, [behaviors])

  useEffect(() => {
    setupEditor()
    editorFoldAll()
    setLoading(false)
    // eslint-disable-next-line
  }, [aceRef.current])

  const editorUnfold = () => {
    editor?.getSession().unfold(null)
  }

  const editorFoldAll = () => {
    editor?.getSession().foldAll(3)
  }

  const setupEditor = () => {
    if (!editor) {
      return
    }

    editor.commands.addCommand({
      name: 'save',
      exec: props.onSave,
      bindKey: { win: 'ctrl-s', mac: 'cmd-s' }
    })
    editor.getSession().setOption('useWorker', false)
  }

  const getBehaviors = () => {
    getProjectBehaviors()
      .then(({ data }) => {
        setBehaviors(data.join('|'))
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const onChange = (newValue, e) => {
    props.updateParentValue(newValue)
    setValue(newValue)
    let error
    try {
      Hjson.parse(newValue)
    } catch (e) {
      error = 'parse error: ' + e.toString()
    }
    if (typeof error !== 'undefined') {
      const row = error.match(/at line ([0-9]*),/)

      aceRef.current?.editor?.getSession()?.setAnnotations([
        {
          row: row[1] - 1,
          column: 0,
          text: error,
          type: 'error'
        }
      ])
    } else {
      aceRef.current?.editor?.getSession()?.clearAnnotations()
    }
  }

  const handleSetFontSize = (e) => {
    setFontSize(parseInt(e.currentTarget.innerText, 10))
  }

  const getEditorThemeMenu = () => (
    <Menu>
      <MenuItem
        icon={theme === 'monokai' ? 'small-tick' : 'blank'}
        text="Dark theme"
        onClick={() => setTheme('monokai')}
      />
      <MenuItem
        icon={theme === 'xcode' ? 'small-tick' : 'blank'}
        text="Light theme"
        onClick={() => setTheme('xcode')}
      />
    </Menu>
  )
  const getEditorFontMenu = () => (
    <Menu>
      <MenuItem icon={fontSize === 15 ? 'small-tick' : 'blank'} text="15" onClick={handleSetFontSize} />
      <MenuItem icon={fontSize === 12 ? 'small-tick' : 'blank'} text="12" onClick={handleSetFontSize} />
      <MenuItem icon={fontSize === 18 ? 'small-tick' : 'blank'} text="18" onClick={handleSetFontSize} />
      <MenuItem icon={fontSize === 20 ? 'small-tick' : 'blank'} text="20" onClick={handleSetFontSize} />
    </Menu>
  )

  if (loading) {
    return <Spinner />
  }

  return (
    <Fragment>
      <Popover className="EditorThemeMenu" content={getEditorThemeMenu()}>
        <Button minimal icon="style" />
      </Popover>
      <Popover className="EditorFontMenu" content={getEditorFontMenu()}>
        <Button minimal icon="font" />
      </Popover>
      <Button
        minimal
        icon={!collapsed ? 'collapse-all' : 'expand-all'}
        text={!collapsed ? 'Collapse All' : 'Expand All'}
        onClick={() => {
          setCollapsed(!collapsed)
          if (collapsed) {
            editorUnfold()
          } else {
            editorFoldAll()
          }
        }}
      />
      <AceEditor
        value={value}
        ref={aceRef}
        mode={props.mode || 'hjson'}
        theme={theme}
        fontSize={fontSize}
        name="SchemaEditor"
        editorProps={{ $blockScrolling: true }}
        height="95%"
        width="100%"
        showPrintMargin={false}
        onChange={onChange}
      />
    </Fragment>
  )
}

export default Editor
