import React from 'react'

import style from './style.module.scss'

const Form = ({ children, onSubmit }) => (
  <form className={style.form} onSubmit={onSubmit}>
    {children}
  </form>
)

export default Form
