import { Button, Classes, Drawer, Intent, Spinner } from '@blueprintjs/core'
import React, { Fragment, useEffect, useState } from 'react'

import handleApiMessages from '../../utils/handleApiMessages'
import { GoatToaster } from '../../utils/GoatToaster'
import { editSchema, getSchema } from '../../Api/Project'
import { renderError } from '../../Shared/RenderError'

import Editor from './Editor'

export default ({ projectId, onClose }) => {
  const [loading, setLoading] = useState(true)
  const [savingSchema, setSavingSchema] = useState(false)
  const [code, setCode] = useState<any>(null)

  useEffect(() => {
    getSchema(projectId)
      .then((response) => {
        setCode({ projectId, value: response.data, mode: 'hjson' })
        setLoading(false)
      })
      .catch((error) => {
        renderError(handleApiMessages(error))
        setLoading(false)
      })
  }, [projectId])

  const handleSaveSchema = (skipClose?) => {
    setSavingSchema(true)
    editSchema(projectId, { config: code.value, type: code.mode })
      .then((response) => {
        GoatToaster.show({
          timeout: 1000,
          icon: 'saved',
          intent: Intent.SUCCESS,
          message: 'Schema Successfully Saved'
        })
        if (!skipClose) {
          onClose()
        }
        setSavingSchema(false)
      })
      .catch((error) => {
        renderError(error)
        setSavingSchema(false)
      })
  }

  const handleClose = () => {
    if (window.confirm('Are you sure you want to cancel? All changes will be lost.')) {
      onClose()
    }
  }

  return (
    <Drawer
      onClose={onClose}
      style={{ width: '95%', height: '99%' }}
      portalClassName="codeEditor"
      title="Edit Schema"
      isOpen={true}
    >
      {loading ? (
        <div className="content">
          <Spinner />
        </div>
      ) : (
        <Fragment>
          <div className={Classes.DIALOG_BODY}>
            <Editor
              updateParentValue={(value) => setCode({ ...code, value: value })}
              onSave={() => handleSaveSchema(true)}
              value={code?.value}
              mode={code?.mode}
              name={code?.name}
            />
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              {!savingSchema && (
                <Fragment>
                  <Button text="Cancel" onClick={handleClose} />
                  <Button icon="floppy-disk" intent={Intent.PRIMARY} text="Save" onClick={handleSaveSchema} />
                </Fragment>
              )}
              {savingSchema && <Spinner className="savingSpinner" size={16} intent={Intent.PRIMARY} />}
            </div>
          </div>
        </Fragment>
      )}
    </Drawer>
  )
}
