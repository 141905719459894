import React from 'react'

import style from './style.module.scss'

const SubmitBtn = ({ disabled, label }) => (
  <button className={style.submitBtn} type="submit" disabled={disabled}>
    {label}
  </button>
)
export default SubmitBtn
