/**
 * Handles simple request responses
 * @param response
 */
export const handleApiMessages = (response: any) => {
  let messages = ''

  if (response) {
    // combined all messages
    if (response?.messages) {
      messages = response.messages.map((message) => message + '\n')
    } else if (response?.errors) {
      // in case of errors, log to console
      // handle uncaught errors
      messages = response.errors.map((message) => message + '\n')
    }
  } else {
    messages = 'This server is going crazy. Please try again later.'
  }

  return messages
}

export default handleApiMessages
