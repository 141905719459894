import React, { Fragment } from 'react'

import logo from '../../assets/logo.svg'

const FormHeader = ({ title }) => {
  return (
    <Fragment>
      <img src={logo} alt="APIgoat logo" width={125} />
      <h1>{title}</h1>
    </Fragment>
  )
}

export default FormHeader
