import { store } from "../../store"
import { removeSessionToken } from "../../utils/Common"

export async function handleError(response) {
  if (!response) {
    return
  }

  const { status } = response

  if (status === 401) {
    removeSessionToken()
    store.dispatch('REMOVE_SESSION_USER')
  }

  if (status !== 200) {
    throw response.data
  }
}
